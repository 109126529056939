import store from "@/store";

// 应用设置

const appSetLanguage = (language: string) => {
  return store.dispatch("app/setLanguage", language);
};

const appCheckIP = () => {
  return store.dispatch("app/checkIP");
};

const appCheckMobile = () => {
  return store.dispatch("app/checkMobile");
};

export default {
  appSetLanguage,
  appCheckIP,
  appCheckMobile
};
