import Vue from "vue";
import VueRouter, { RouteConfig, RawLocation } from "vue-router";

const publicPath = "/:language(cn|en)?";

export declare interface Paths {
  root: string;
  home: string;
  aboutUs: string;
  rapidIceSwitch: string;
  blackDiamond75: string;
  blackDiamond75V2: string;
  blackDiamond75RT: string;
  petbrick65: string;
  afterSales: string;
  termsOfService: string;
  privacyPolicy: string;
}

export const paths: Paths = {
  root: "/",
  home: "/",
  aboutUs: "/about-us/",
  rapidIceSwitch: "/rapid-ice-switch/",
  blackDiamond75: "/black-diamond-75/",
  blackDiamond75V2: "/black-diamond-75-v2/",
  blackDiamond75RT: "/black-diamond-75-rt/",
  petbrick65: "/petbrick-65/",
  afterSales: "/after-sales/",
  termsOfService: "/terms-of-service/",
  privacyPolicy: "/privacy-policy/"
};

const routes: Array<RouteConfig> = [
  {
    path: publicPath + paths.root,
    name: "MainLayout",
    component: () => import("./layouts/MainLayout.vue"),
    children: [
      {
        path: publicPath + paths.home,
        name: "Home",
        component: () => import("./views/Home.vue")
      },
      {
        path: publicPath + paths.aboutUs,
        name: "About us",
        component: () => import("./views/AboutUs.vue")
      },
      {
        path: publicPath + paths.rapidIceSwitch,
        name: "Rapid Ice Switch",
        component: () => import("./views/RapidIceSwitch.vue")
      },
      {
        path: publicPath + paths.blackDiamond75,
        name: "Black Diamond 75",
        component: () => import("./views/BlackDiamond75.vue")
      },
      {
        path: publicPath + paths.blackDiamond75V2,
        name: "Black Diamond 75 V2",
        component: () => import("./views/BlackDiamond75V2.vue")
      },
      {
        path: publicPath + paths.blackDiamond75RT,
        name: "Black Diamond 75 RT",
        component: () => import("./views/BlackDiamond75RT.vue")
      },
      {
        path: publicPath + paths.petbrick65,
        name: "PETBRICK 65",
        component: () => import("./views/Petbrick65.vue")
      },
      {
        path: publicPath + paths.afterSales,
        name: "Return & Refund Policy",
        component: () => import("./views/AfterSales.vue")
      },
      {
        path: publicPath + paths.termsOfService,
        name: "Terms of Service",
        component: () => import("./views/TermsOfService.vue")
      },
      {
        path: publicPath + paths.privacyPolicy,
        name: "Privacy Policy",
        component: () => import("./views/PrivacyPolicy.vue")
      }
    ]
  },
  {
    path: "*",
    redirect: paths.root
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes
});

const VueRouterPush = VueRouter.prototype.push;
const VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location: RawLocation) {
  return (VueRouterPush.call(this, location) as any).catch((err: Error) => err);
};
VueRouter.prototype.replace = function replace(location: RawLocation) {
  return (VueRouterReplace.call(this, location) as any).catch(
    (err: Error) => err
  );
};

Vue.use(VueRouter);
Vue.prototype.$paths = paths;

export default router;
