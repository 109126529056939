import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  modules,
  getters,
});

export { default as dispatch } from "./dispatch";

/* eslint-disable */
export type { RootState } from "@/store/modules";
