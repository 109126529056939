import VConsole from "vconsole";

const isTest =
  location.href.includes("test.dry---studio.com") ||
  !location.href.includes("dry---studio.com");

if (isTest || new URL(window.location.href).searchParams.get("test"))
  new VConsole({ theme: "dark" });

const base = `/api`;

export default {
  isTest,
  checkIP: `${base}/geo-ip/check` // IP 归属地查询
};
