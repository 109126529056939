








































































































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "VFooter",
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  computed: {
    ...mapState("app", {
      isMobile: "isMobile",
      language: "language",
      isCN: "isCN"
    })
  },
  methods: {
    setLanguage(language: string) {
      const oldLanguage = this.$route.params.language;
      if (oldLanguage) {
        this.$router.replace({
          path: this.$route.path.replace(oldLanguage, language),
          query: this.$route.query
        });
      } else {
        this.$router.replace({
          path: "/" + language + this.$route.path,
          query: this.$route.query
        });
      }
    },
    onPush(path: string) {
      this.$router.push(path);
    },
    onOpen(url: string) {
      window.open(url, "_blank");
    }
  }
});
